import LoginService from '../service/login-service';
const loginService = new LoginService();

const routes = [{
    path: '/login',
    name: 'Engineer Login',
    component: () => import('../components/PublicLogin.vue'),
    meta: {
      title: 'EMSD e-Platform for periodic examination photos submission'
    }
  },
  {
    path: '/updatepw',
    name: 'Engineer Update Password',
    component: () => import('../components/UpdatePassword.vue'),
    beforeEnter(to, from, next) {
      if (!loginService.isLogin()) {
        next({
          path: '/login'
        });
      } else {
        next();
      }
    },
    meta: {
      title: 'EMSD e-Platform for periodic examination photos submission'
    }
  },
  {
    path: '/search',
    name: 'Submission search',
    component: () => import('../components/SubmissionSearch.vue'),
    beforeEnter(to, from, next) {
      if (!loginService.isLogin()) {
        next({
          path: '/login'
        });
      } else {
        next();
      }
    },
    meta: {
      title: 'EMSD e-Platform for periodic examination photos submission'
    }
  },
  {
    path: '/view_submission/:submission_id',
    name: 'Submission view',
    props: route => ({
      action: 'view',
      id: route.params.submission_id
    }),
    component: () => import('../components/SubmissionView.vue'),
    beforeEnter(to, from, next) {
      if (!loginService.isLogin()) {
        next({
          path: '/login'
        });
      } else {
        next();
      }
    },
    meta: {
      title: 'EMSD e-Platform for periodic examination photos submission'
    }
  },
  {
    path: '/update_submission/:submission_id',
    name: 'Submission update',
    props: route => ({
      action: 'update',
      id: route.params.submission_id
    }),
    component: () => import('../components/SubmissionUpdate.vue'),
    beforeEnter(to, from, next) {
      if (!loginService.isLogin()) {
        next({
          path: '/login'
        });
      } else {
        next();
      }
    },
    meta: {
      title: 'EMSD e-Platform for periodic examination photos submission'
    }
  },
  {
    path: '/create_submission/:location_id',
    name: 'Submission create',
    props: route => ({
      action: 'create',
      id: route.params.location_id
    }),
    component: () => import('../components/SubmissionDetails.vue'),
    beforeEnter(to, from, next) {
      if (!loginService.isLogin()) {
        next({
          path: '/login'
        });
      } else {
        next();
      }
    },
    meta: {
      title: 'EMSD e-Platform for periodic examination photos submission'
    }
  },
  {
    path: '*',
    redirect: '/login'
  }
]

export default routes