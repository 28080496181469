import Cookies from 'js-cookie'
export default class LoginService {
    isLogin() {
        //check the token payload cookie exist or not
        var tokenPayload = Cookies.get('token_payload');

        if (tokenPayload != undefined) {
            //have token
            //check the token expired or not


            var payloadObj = null;
            try {
                payloadObj = JSON.parse(atob(tokenPayload));
                // console.log(payloadObj);
            } catch (err) {
                //any err when doing the atob
                console.log(err);
                //invalid payload
                console.log('invalid payload');
                Cookies.remove('token_payload');
                return false;
            }

            // console.log(Date.now());

            if ((payloadObj.exp * 1000) < Date.now()) {
                //clean payload
                console.log('token expired');
                Cookies.remove('token_payload');
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    getEngineerNo() {
        //check the token payload cookie exist or not
        var tokenPayload = Cookies.get('token_payload');

        if (tokenPayload != undefined) {
            var payloadObj = null;
            try {
                payloadObj = JSON.parse(atob(tokenPayload));
                // console.log(payloadObj);
            } catch (err) {
                //any err when doing the atob
                console.log(err);
                //invalid payload
                console.log('invalid payload');
                Cookies.remove('token_payload');
                return null;
            }

            return payloadObj.engineer_no
        } else {
            return null;
        }
    }

    getExpiry() {
        //check the token payload cookie exist or not
        var tokenPayload = Cookies.get('token_payload');

        if (tokenPayload != undefined) {
            var payloadObj = null;
            try {
                payloadObj = JSON.parse(atob(tokenPayload));
                // console.log(payloadObj);
            } catch (err) {
                //any err when doing the atob
                console.log(err);
                //invalid payload
                console.log('invalid payload');
                Cookies.remove('token_payload');
                return null;
            }

            return payloadObj.exp * 1000
        } else {
            return null;
        }
    }

    setEngineerType(type) {
        Cookies.set('eng_type', type, {
            expires: 1 / 24,
            path: '/'
        })
    }

    getEngineerType() {
        return Cookies.get('eng_type');
    }

    logout() {
        Cookies.remove('token_payload');
        Cookies.remove('eng_type');
    }
}